import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, switchMap, take, throwError } from 'rxjs';
import { IResponseAuth } from 'src/app/models/currentUser.interface';
import { environment } from 'src/environments/environment';
import { CurrentUser } from '@app-models/currentUser.interface';

const API_URL = environment.authIdp.CST.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class CustomService {
  constructor(private http: HttpClient) {}

  private _handleError(error: HttpErrorResponse) {
    let errorMessage = 'No se obtuvo respuesta del servidor de autenticación';
    if (error.error.data) {
      errorMessage = error.error.data.userMessage;
    }
    return throwError(() => new Error(errorMessage));
  }

  /**
   * Metodo para realizar la autenticación de usuario externo en el sistema
   */
  public login(credentials: any): Observable<any> {
    // http Headers necesarios para la peticion de autenticacion
    const httpOptionsAuth = {
      
    };

    // Realiza la peticion del token de autenticacion para el login
    return this.http
      .post<IResponseAuth>(`${environment.autorizacion}/oauth/token?grant_type=client_credentials`, {}, httpOptionsAuth)
      .pipe(
        take(1),
        catchError((error) => {
          return of(error);
        }),
        switchMap((response) => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: `Bearer ${response.access_token}`,
            }),
          };
          return this.http
            .post<any>(
              `${API_URL}/SAJV2-AUTHENTICATION-V1/api/auth/login`,
              { ...credentials },
              {
                headers: httpOptions.headers,
                observe: 'response' as 'body',
              }
            )
            .pipe(
              map((responseLogin) => {
                return responseLogin?.headers?.get('X-Auth-Token');
              })
            );
        })
      );
  }

  public fetchProfile(): Observable<any> {
    return this.http.get(`${environment.backendURL}/usuarios/actual`).pipe(
      map(({ datos }: any) => this.convertirUsuarioExternoInterno(datos)),
      catchError(this._handleError)
    );
  }

  /**
   * Convierte usuarios obtenidos mediante el servicio @see getUsuarioActual (de esta misma clase)
   * al tipo `CurrentUser`.
   */
  private convertirUsuarioExternoInterno(datos): CurrentUser {
    const externalUser: CurrentUser = {
      apellidopaterno: datos.primerApellido,
      apellidomaterno: datos.segundoApellido,
      curp: null,
      descripcioncentro: null,
      descripcionpuesto: null,
      estatus: null,
      fechaalta: null,
      fechanacimiento: null,
      inicialregion_EC: null,
      nemp_jefe: null,
      nombre: datos.nombre,
      nombreregion_EC: null,
      numerocentro: null,
      numerociudad: null,
      numeroempleado: datos.numeroColaborador?.toString(),
      numeroempresa: null,
      numeropuesto: null,
      numeroregion_EC: null,
      puesto_jefe: null,
      seccion_jefe: null,
      seccioncentro: null,
      sexo: null,
      token: null,
    };
    return externalUser;
  }
}
