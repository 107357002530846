import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponseDTO } from './modules/ApiResponse/ApiResponse.interface';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TokenService {

  public constructor(public httpClient: HttpClient) { }


  public obtenerTokenAplicativo(): Observable<ApiResponseDTO<any>> {
    return this.httpClient
      .post<ApiResponseDTO<any>>(`${environment.microTokenHomologado}/token/obtener-token`, {
        appId: `${environment.authIdp.SSO.appId}`,
        appKey: `${environment.authIdp.SSO.appKey}`,
      })
      .pipe(
        map((response) => response.data.token)
      );
  }


}
