import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GeneralResponse } from '../models/general.interface';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  /**
   * Crea una instancia de shared service.
   * @param baseService
   */
  public constructor(private baseService: BaseService) {}

  /**
   * Consulta un colaborador por numero de colaborador.
   * @param numeroColaborador
   */
  public getIDUsuario(numeroColaborador: number) {
    return this.baseService.post<GeneralResponse>(`${environment.backendURL}/usuarios/usuarioPorNumeroColaborador`, {
      numeroColaborador,
    });
  }

  public getCorreoUsuario(correo: string) {
    return this.baseService.post<GeneralResponse>(`${environment.backendURL}/usuarios/busca-usuarios-locales`, {
      numColaborador: '',
      correo: correo,
      nombre: '',
    });
  }

  /**
   * Consulta un archivo por identificador.
   * @param idFile - Identificador de usuario a consultar
   */
  public getDataVisor(idFile: string) {
    return this.baseService.get<any>(`${environment.backendURL}/documento/base64/${idFile}`);
  }

  /**
   * Método que invoca al servicio para descargar un archivo.
   * @param idArchivo - Identificador del archivo.
   * @returns Observable con los datos de respuesta del servicio.
   */
  public descargarArchivo(idArchivo: string): Observable<Blob> {
    let headers = new HttpHeaders();
    headers = headers.set('observe', 'response');

    return this.baseService.get(`${environment.backendURL}${environment.urls.documento}/file/${idArchivo}`, {
      headers,
      responseType: 'blob',
    });
  }
}
