import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { map, Observable, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SecureStorageService } from '../services/secure-storage.service';
import { TokenService } from '../services/token.service';

/**
 * Constante Header InterceptorMensajes
 */
export const INTERCEPTOR_JWT = 'InterceptorJWT';
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private storageService: SecureStorageService,
              private tokenService: TokenService
  ) {}

  private setTokenHeaders(request: HttpRequest<any>) {
    const headers: Record<string, string> = {};

    headers['Authorization'] = this.storageService.getItem('token');

    if (environment.idpSpecificationRequired) {
      headers['IdP'] = this.storageService.getItem('idp');
    }

    return request.clone({ setHeaders: headers });
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let requireAuth = environment.interceptorApproach.some((source) => request.url.includes(source));

    if(request.url.includes("ms-exp-personal")){
      return this.setTokenAplicativoHeaders(request).pipe(
        switchMap((modifiedRequest) => next.handle(modifiedRequest))
      );
    }

    if (request.headers.has(INTERCEPTOR_JWT) && Boolean(request.headers.get(INTERCEPTOR_JWT))) {
      requireAuth = true;
    }

    if (requireAuth && localStorage.getItem('idp') && localStorage.getItem('token')) {
      request = this.setTokenHeaders(request);
    }
    return next.handle(request);
  }

  private setTokenAplicativoHeaders(request: HttpRequest<any>) {
    return this.tokenService.obtenerTokenAplicativo().pipe(
      take(1),
      map((response) => {
        const token = response;
        return request.clone({
          setHeaders: token ? { Authorization: `Bearer ${token}` } : {},
        });
      })
    );
  }

}
