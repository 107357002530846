import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseAction, IResponseAuth, IResponseSingle } from '@app-shared/models/IResponse';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  EstablecerContrasena,
  RespuestaCrearContrasena,
  RespuestaValidarUsuario,
  UserCredentials,
} from '../models/login.interface';


@Injectable({
  providedIn: 'root',
})
export class LoginExternoService {
  private CONTENT_TYPE = 'application/json';

  /**
   * Crea una instancia de LoginExternoService
   * @constructor
   * @param http
   */
  public constructor(private http: HttpClient) {}

  /**
   * Ejecuta petición a web service para el envio del correo para recuperar contraseña
   * @param datosRecuperacion - Parametros con los datos del usuario.
   * @returns Observable con los datos de respuesta
   */
  public recuperarPassword(correo: string): Observable<IResponseAction> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': this.CONTENT_TYPE,
      }),
    };
    return this.http.get<any>(`${environment.backendURL}/usuarios/correo-recuperar-contrasenna/${correo}`, httpOptions);
  }

  /**
   * Ejecuta la peticion a web service para obtener el token de autenticacion
   * @param username
   * @param password
   * @returns {Observable<IResponseAuth>}
   */
  public tokenCrearPassword(): Observable<IResponseAuth> {
    const httpOptions = {
      
    };

    return this.http.post<IResponseAuth>(
      `${environment.autorizacion}/oauth/token?grant_type=client_credentials`,
      {},
      httpOptions
    );
  }

  /**
   * Ejecuta la peticion para determinar la contraseña
   * @param datos
   * @param token
   * @returns {Observable<IResponseSingle<RespuestaCrearContrasena>>}
   */
  public crearPassword(
    datos: EstablecerContrasena,
    token: string
  ): Observable<IResponseSingle<RespuestaCrearContrasena>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': this.CONTENT_TYPE,
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.http.post<IResponseSingle<RespuestaCrearContrasena>>(
      `${environment.autenticacion}/createpassword`,
      datos,
      httpOptions
    );
  }

  /**
   * Ejecuta una consulta para determinar si el usuario es valido para registrar una contrasenia
   * @param usuario
   * @returns
   */
  public validarCorreo(usuario: string): Observable<IResponseSingle<RespuestaValidarUsuario>> {
    return this.http.get<IResponseSingle<RespuestaValidarUsuario>>(
      `${environment.backendURL}/usuarios/validate-correo-fecha/${usuario}`
    );
  }

  /**
   * Ejecuta la consulta para el login del sistema
   * @param datos
   * @param token
   * @returns
   */
  public iniciarSesion(datos: UserCredentials, token: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': this.CONTENT_TYPE,
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.http.post<any>(`${environment.autenticacion}/api/auth/login`, datos, {
      headers: httpOptions.headers,
      observe: 'response',
    });
  }

  /**
   * Ejecuta petición a web service para cambiar la contraseña
   * @param datos
   * @param token
   * @returns
   */
  public cambiarPassword(datos: EstablecerContrasena, token: string): Observable<IResponseSingle<any>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': this.CONTENT_TYPE,
        Authorization: `Bearer ` + token,
      }),
    };
    return this.http.post<IResponseSingle<any>>(`${environment.autenticacion}/changepassword`, datos, httpOptions);
  }
}
