import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';

import localeEsMx from '@angular/common/locales/es-MX';
registerLocaleData(localeEsMx);

// Import Global Modules
import { LoaderModule, LoaderService } from '@app-loader';
import { ToasterModule } from '@app-toaster';
import { AppRoutingModule } from './app-routing.module';

// Import Global Services
import { ConfigService } from './services/config.service';
import { AadService } from './services/idps/aad.service';
import { SecureStorageService } from './services/secure-storage.service';

// Import Global Helpers
import { CancelGuard } from '@app-guards/Cancel.guard';
import { ConfirmGuard } from '@app-guards/Confirm.guard';
import { AuthGuard } from './guards/Auth.guard';
import { JwtInterceptor } from './helpers/jwt.interceptor';

// Import containers
import { FullLayoutModule } from './containers/full-layout/full-layout.module';
import { SimpleLayoutModule } from './containers/simple-layout/simple-layout.module';

// Import 3rd party components
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginModule } from '@app-modules/login/login.module';
import { GestorModalOptions, GestorModalRef, GestorModalService } from '@app-services/gestor-modal.service';
import { GenericComponentsModule } from '@app-shared/generic-components/generic-components.module';
import { GenericRolesModule } from '@app-shared/generic-roles/generic-roles.module';
import { HttpErrorInterceptor } from '@app-shared/interceptors/http-error.interceptor';
import { LoadingInterceptor } from '@app-shared/interceptors/loading.interceptor';
import { MensajesInterceptor } from '@app-shared/interceptors/mensajes.interceptor';
import { PrimeNgExportsModule } from '@app-shared/prime-ng-exports/prime-ng-exports.module';
import { BaseService } from '@app-shared/services';
import { SharedModule } from '@app-shared/shared.module';
import {
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { appOverrides } from 'src/app/app-overrides';

// MSAL Config
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
const { tenantId, clientId, authorityUrl, redirectUri, graphUrl } = environment.authIdp.AAD;
const msalAuthConfiguration = {
  auth: {
    clientId,
    authority: `${authorityUrl}/${tenantId}`,
    redirectUri: redirectUri,
  },
  cache: { cacheLocation: 'sessionStorage', storeAuthStateInCookie: isIE },
};
const msalGuardConfiguration: MsalGuardConfiguration = {
  interactionType: InteractionType.Redirect, // MSAL Guard Configuration
  authRequest: { scopes: ['User.Read'] },
};
const msalInterceptorConfiguration: MsalInterceptorConfiguration = {
  interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
  protectedResourceMap: new Map([[`${graphUrl}/v1.0/me`, ['User.Read']]]),
};

// App Initializer Config
export function ConfigLoader(configService: ConfigService) {
  return () => configService.getJSON();
}
export function StorageLoader(storageService: SecureStorageService) {
  return () => {
    storageService.load();
    return storageService.loaded;
  };
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ToasterModule,
    LoaderModule,
    SimpleLayoutModule,
    FullLayoutModule,
    MsalModule.forRoot(
      new PublicClientApplication(msalAuthConfiguration),
      msalGuardConfiguration,
      msalInterceptorConfiguration
    ),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    GenericComponentsModule,
    PrimeNgExportsModule,
    GenericRolesModule.forRoot(),
    LoginModule,
  ],
  declarations: [AppComponent],
  providers: [
    ConfigService,
    SecureStorageService,
    AadService,
    { provide: LOCALE_ID, useValue: 'es-MX' },
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigLoader,
      deps: [ConfigService, LoaderService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: StorageLoader,
      deps: [SecureStorageService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    BaseService,
    AuthGuard,
    MsalGuard,
    ...appOverrides(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MensajesInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    ConfirmGuard,
    CancelGuard,
    GestorModalService,
    GestorModalRef,
    GestorModalOptions,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
