import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-root",
  template: `
    <app-toaster></app-toaster>
    <ng-container *ngIf="!isIframe">
      <router-outlet></router-outlet>
    </ng-container>
  `,
})
export class AppComponent implements OnInit {
  public isIframe = false;

  public ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
  }
}
