import { Injectable } from '@angular/core';
import { IResponseSingle } from '@app-shared/models/IResponse';
import { BaseService } from '@app-shared/services/base.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Personal } from './personal';
import { ApiResponseDTO } from '@app-services/modules/ApiResponse/ApiResponse.interface';
import {
  Colaborador,
  ColaboradorResponse,
  FiltosBusquedaColaborador,
} from '@app-modules/generic-search-components/models/colaborador.interface';
import { FiltosBusquedaCentros } from '@app-modules/generic-search-components/models/centros.interface';
import { EmpleadosPersonal } from '@app-modules/modulos-base/models/empleados-personal';

@Injectable({
  providedIn: 'root',
})
export class PersonalService {
  /**
   * Creates an instance of personal service.
   * @param baseService servicio para realizar llamadas al backend.
   */
  public constructor(private baseService: BaseService) {}

  /**
   * Método que obtiene la informacion de un colaborador de IFS
   * @param numeroPersona
   * @returns
   */
  public getColaborador(numeroPersona: number | string): Observable<ApiResponseDTO<Personal>> {
    const filtros: FiltosBusquedaColaborador = {
      numerosColaborador: [+numeroPersona],
      nombreCentro: '',
      nombreColaborador: '',
      apellidoPaterno: '',
      apellidoMaterno: '',
      numeroCentro: 0,
      estatusColaborador: '',
      paginacion: {
        pageable: false,
        page: 0,
        size: 10,
        orderDirection: 'DESC',
        orderBy: 'numeroColaborador',
      },
    };
    return this.baseService.post<ApiResponseDTO<Personal>>(
      `${environment.microPersonal}/busqueda/colaboradores`,
      filtros,
      {
        encabezadosInterceptoresCustom: {
          interceptarErrores: false,
          interceptorMensajes: false,
        },
      }
    );
  }

  /**
   * Método que obtiene la informacion de un colaborador externo por correo.
   */
  public getColaboradorExternoPorCorreo(id: string): Observable<IResponseSingle<Personal>> {
    return this.baseService
      .get<IResponseSingle<Personal>>(`${environment.backendURL}/personal/empleadoCorreo/?correo=${id}`, {
        encabezadosInterceptoresCustom: { interceptorMensajes: false, interceptarErrores: false },
      })
      .pipe(map((response) => response as IResponseSingle<Personal>));
  }

  /**
   * Obtiene una lista de centros.
   */
  public obtenerCentros(filtros: FiltosBusquedaCentros): Observable<ApiResponseDTO<any>> {
    return this.baseService.post(`${environment.microPersonal}/centros`, filtros, {
      encabezadosInterceptoresCustom: {
        interceptarErrores: false,
        interceptorMensajes: false,
      },
    });
  }

  /**
   * Obtiene una lista de colaboradores.
   */
  public obtenerColaboradores(filtros: FiltosBusquedaColaborador): Observable<any> {
    return this.baseService
      .post<ApiResponseDTO<ColaboradorResponse>>(`${environment.microPersonal}/busqueda/colaboradores`, filtros, {
        encabezadosInterceptoresCustom: {
          interceptarErrores: false,
          interceptorMensajes: false,
        },
      })
      .pipe(
        map((response) => {
          response.data.personas =
            response?.data.personas.map((colaborador: Colaborador) => {
              colaborador.nomStatus = colaborador.status === 1 ? 'No Activo' : 'Activo';
              return colaborador;
            }) ?? [];

          return response;
        })
      );
  }

  public getEmpleadoPersonal(numeroPersona): Observable<ApiResponseDTO<EmpleadosPersonal>> {
    const datos = {
      numerosColaborador: [numeroPersona],
      paginacion: {
        pageable: false,
      },
    };
    return this.baseService.post<ApiResponseDTO<EmpleadosPersonal>>(
      `${environment.microPersonal}/busqueda/colaboradores`,
      datos
    );
  }
}
