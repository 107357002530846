import { Paths } from '../assets/json/parametrosGlobalesEnviroment';
import packageFile from '../../package.json';

export const environment = {
  appId: 'd2432054-ba80-4303-b16e-34028dade87a',
  production: true,
  configFile: 'assets/settings/settings.json',
  recaptcha: {
    scriptUrl: 'https://www.google.com/recaptcha/enterprise.js',
    siteKey: '6LdHZ_0dAAAAAE_OxObOIF3odKLJKXj2Vu_GZBHk',
  },
  authMechanisms: {
    azureAD: true,
    huella: false,
    colaboradorDigital: false,
    authCode: false,
    custom: false
  },
  authIdp: {
    AAD: {
      tenantId: '76d81621-b9a9-4786-bb8f-a2efa839eee2',
      clientId: '93c0b058-5021-4da5-b486-7b7814759f54',
      authorityUrl: 'https://login.microsoftonline.com',
      redirectUri: 'https://sajmexico.coppel.com',
      graphUrl: 'https://graph.microsoft.com',
    },
    SSO: {
      appId: 'd2432054-ba80-4303-b16e-34028dade87a',
      appKey: '2684f3b27dd49ec4b724d93b5d55a9ce71f412bba0c670c8f4092e5d42493b29',
      channel: 'ecommerce-app-244022',
      apiUrl: 'https://appcloud.coppel.com/api/sso',
    },
    CST: {
      apiUrl: 'https://sajmexico.coppel.com/sajv2/api/v1',
    },
  },
  interceptorApproach: [
    'https://sajmexico.coppel.com',
    'https://appcloud.coppel.com/api/sso/v2/login',
    'https://appcloud.coppel.com/api/sso/v1/me',
    'https://appcloud.coppel.com/api/sso/v2/verify',
  ],
  idpSpecificationRequired: true,
  // Custom properties
  inHouse: [true, false],
  envName: 'PROD',
  versions: {
    app: packageFile.version,
    angular: packageFile.dependencies['@angular/core'],
  },
  backendURL: 'https://sajmexico.coppel.com/sajv2/api',
  microEUI: 'https://sajmexico.coppel.com/eu/api',
  autorizacion: 'https://sajmexico.coppel.com/SAJV2-MS-SECURITY-AUTHORIZATION-SERVER-V0',
  autenticacion: 'https://sajmexico.coppel.com/sajv2-authentication/api/v1',
  microAutoridadLaboral: 'https://sajmexico.coppel.com/ms-exp-jc-catalogos/api/v1',
  microMulta: 'https://sajmexico.coppel.com/ms-exp-ja-multa/api/v1',
  microQueja: 'https://sajmexico.coppel.com/ms-exp-ja-queja/api/v1',
  microPersonal: 'https://sajmexico.coppel.com/ms-exp-personal/api/v1',
  microNotificacion: 'https://sajmexico.coppel.com/SAJV2-MS-EXP-NOTIFICACION-V0',
  microTokenHomologado: 'https://juridicosajv3.coppel.com/ms-login-homologado/api/v1/exp',
  urls: Paths.urls,
  permitirDescargaArchivosVisor: true,
  permitirDehabilitarRoles: false,
  habilitarAdministradorRoles: true,
};
